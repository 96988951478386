@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 12px;
    color: #282828;
    background: #fafafa;
}

body,
html {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    width: 100%;
}

* {
    box-sizing: border-box;
}

#st-1 > div.st-btn.st-first {
    background: #5667ff;
    border-color: #5667ff;
}

#st-1 > div.st-btn.st-first > span {
    color: #fff;
}

#st-1 > div.st-btn.st-first > img {
    filter: grayscale(0);
    display: none;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/assets/fonts/Gilroy/Gilroy-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

/* @font-face {
	font-family: 'EmojiMart';
	src: url("/assets/fonts/emoji-mart.ttf") format('truetype');
    font-display: block;
} */


@font-face {
  font-family: 'Source Serif Pro';
  src: url('/assets/fonts/SourceSerifPro-Regular.otf') format('otf');
  font-weight: 400;
  font-style: normal;
}


@font-face {
    font-family: 'Gilroy';
    src: url('/assets/fonts/Gilroy/Gilroy-RegularItalic.woff2') format('woff2');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/assets/fonts/Gilroy/Gilroy-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/assets/fonts/Gilroy/Gilroy-MediumItalic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/assets/fonts/Gilroy/Gilroy-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/assets/fonts/Gilroy/Gilroy-SemiBoldItalic.woff2') format('woff2');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/assets/fonts/Gilroy/Gilroy-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/assets/fonts/Gilroy/Gilroy-ExtraBold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/assets/fonts/Gilroy/Gilroy-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
}

@keyframes scroll-left {
    0% {
        -moz-transform: translateX(100%);
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
    100% {
        -moz-transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}

.show-only-mobile {
    display: none;
}
.show-only-desktop {
    display: block;
}

@media (max-width: 767px) {
    .show-only-mobile {
        display: block;
    }
    .show-only-desktop {
        display: none;
    }
}

body {
    font-family: 'Gilroy', sans-serif;
    font-size: 12px;
    background: #fafafa;
}

/*Webflow*/
.bold-text-2 {
    width: 100%;
    font-size: 32px;
}
.text-block-2 {
    text-align: left;
    padding: 16px 16px 16px 0;
}
b,
strong {
    font-weight: 700;
}

.about-venture-flex {
    font-size: 18px;
}

.pre-wrap {
    white-space: 'pre-wrap';
}

.dark-shimmer .shimmer {
    background: #ffffff2e;
}

.markdown li,p{
    margin-bottom: 20px;
}
